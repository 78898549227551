.container {
    width: 100%;
    max-width: 1140px;
    height: 100vh;
    max-height: 100vh;
    margin: 0 auto;
    background-color: white;
}

.header-container {
    width: 100%;
    background-color: white;
    z-index: 100;
}

.header {
    margin-right: .6rem;
    margin-left: .6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1.5625rem;
    padding-bottom: 2rem;
    background-color: white;
}

.header p {
    font-weight: 700;
    font-size: 19px;
}

.close-button {
    height: 1.6875rem;
    width: 1.6875rem;
    background-color: black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.statuses {
    background-color: white;
    margin-left: 0.6rem;
    margin-right: 0.6rem;
    display: flex;
    justify-content: space-around;
    margin-bottom: 2rem;
}

.statuses div {
    box-sizing: border-box;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 5px;
}
.statuses h4 {
    cursor: pointer;
}

.active {
    box-sizing: border-box;
    border-bottom: solid 1.5px black;
}

.activeRed {
    box-sizing: border-box;
    border-bottom: solid 1.5px red;
}
.brandLogoWrap{
    width:150px;
    height: 80px;
    background-size:contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: auto;
}
