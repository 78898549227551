/* .videos-container {
    width: 100%;
    min-height: 48.5rem;
    height: 100%;
    padding-left: 0.6875rem;
    padding-right: 0.6875rem;
    display: flex;
    gap: 0.25rem;
    flex-wrap: wrap;
} */
.parent-container {
    width: 100%;
    height: 73.5vh;
    overflow: hidden;
    overflow-y: auto;
}


.empty-container {
    width: 100%;
    min-height: 90vh;
    height: 100%;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


/* .videos-container {
    width: auto;
    height: 800px;
    padding-left: 0.6875rem;
    padding-right: 0.6875rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
    align-content: flex-start;
    margin: 0 auto;
    background-color: white;
    overflow: hidden;
    overflow-y: auto;
    overflow-x: hidden;
} */

.videos-container {
    width: 100%;
    min-height: 800px;
    padding-left: 0.6875rem;
    padding-right: 0.6875rem;
    display: grid;
    grid-template-columns: repeat(4, minmax(135px, 1fr));;
    grid-auto-rows: 354px;
    gap: 12px;
    background-color: white;
    overflow: hidden;
    overflow-y: auto;
    overflow-x: hidden;
}

.videos-container::-webkit-scrollbar{
    width: 5px;
}
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

@media screen and (max-width: 800px) {
    .videos-container {
        grid-template-columns: repeat(3, minmax(135px, 1fr));
        grid-auto-rows: 164px;
        gap: 6px;
    }
}
@media screen and (max-width: 470px) {
    .videos-container {
        grid-template-columns: repeat(2, minmax(135px, 1fr));
        grid-auto-rows: 164px;
        gap: 6px;
    }
}