body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    font-family: 'Roboto', sans-serif;
    
}
html {
    height: -webkit-fill-available;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 36px;
}
::-webkit-scrollbar-thumb:hover {
  background: #b6b6b6;
}
::-webkit-scrollbar-thumb:active {
  background: #e1e1e1;
}
::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #666666;
}
::-webkit-scrollbar-track:active {
  background: #333333;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

.toast-notification {
  background-color: #FFFFFF;
  color: #000000;
  padding: 20px;
  font-size: 14px;
}

.Toastify .Toastify__toast-container {
  color: rgb(36, 36, 36);
  font-weight: bold;
}

.Toastify .Toastify__toast-container .Toastify__toast {
  background: #FFFFFF;
}

.Toastify .Toastify__toast-container .Toastify__toast .Toastify__toast-body {
  text-align: center;
  letter-spacing: 0.05em;
}

.Toastify .Toastify__toast-container .Toastify__toast .Toastify__close-button{ 
  color: #000000;
}

.Toastify .Toastify__toast-container .Toastify__toast .Toastify__progress-bar{ 
  color: #000000;
}