.play-shop-container-mobile {
    position: relative;
    width: 100%;
    max-width: 768px;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    margin: 0 auto;
    background-color: white;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

#play-video {
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    cursor: pointer;
    width: 50px;
    height: 50px;
}

.left-arrow {
    position: relative;
    z-index: 10;
    width: 50px;
    height: 50px;
    background-color: rgba(107, 107, 107, 0.85);
    border-radius: 50%;
    top: 0;
    left: 0;
    margin-top: 5px;
    margin-left: 5px;
    padding-top: 0.9rem;
    padding-left: 0.8rem;
    padding-right: 0.875rem;
    padding-bottom: 1rem;
    cursor: pointer;
    margin-bottom: 10px;
    transform: scale(0.7);
}

.shop-arrow {
    width: 15px;
    height: 15px;
    padding-top: 5px;
    margin-left: 0.7rem;
    transform: rotate(90deg);
}

.shop-down {
    transform: rotate(-90deg);
    transition: 0.5s;
}

/* .context-container-mobile {
    max-height: 30%;
    overflow: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;  
    scrollbar-width: none;  
}

.context-container-mobile::-webkit-scrollbar {
    display: none;
} */

#previous-container {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    z-index: 4;
    cursor: pointer;
    opacity: 0.6;
}

#next-container {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    z-index: 4;
    cursor: pointer;
    opacity: 0.6;
}


.next {
    position: absolute;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    cursor: pointer;
    margin: 0 auto;
    box-shadow: 0px 2px 19px -2px rgba(0,0,0,0.77);
    -webkit-box-shadow: 0px 2px 19px -2px rgba(0,0,0,0.77);
    -moz-box-shadow: 0px 2px 19px -2px rgba(0,0,0,0.77);
}

#previous {
    top: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

#next {
    bottom: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

#next img {
    transform: rotate(-180deg);
}


.react-player-mobile {
    position: absolute;
    top: 0;
    left: 0;
    line-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
}

.react-player-mobile video {
    object-fit: none !important;
    width: 100%;
    height: 100%;
    outline: none;
}

.react-player-mobile {
    position: absolute;
    top: 0;
    left: 0;
    line-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.react-player-mobile img {
    object-fit: cover !important;
    outline: none;
}

.controls {
    position: absolute;
    z-index: 3;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-container {
    padding: 5px 5px;
    background-color: #30353D;
    border-radius: 50%;
    height: 80px;
    width: 80px;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 7;
    bottom: 65%;
    left: 50%;
    transform: translateX(-50%);
}

.logo-container img {
    margin-left: 5px;
    width: 50%;
    height: 50%;
}

.recorded-live-message {
    position: absolute;
    z-index: 5;
    color: white;
    bottom: 58%;
}

.upcoming-logo-container {
    padding: 5px 5px;
    background-color: #30353D;
    border-radius: 50%;
    height: 80px;
    width: 80px;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    bottom: 65%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.upcoming-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; width: 100%;
    height: 100%;
    position: absolute;
    color: white;
}

.upcoming-logo-container img {
    margin-left: 5px;
    width: 50%;
    height: 50%;
}

.loading {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.shop-mobile {
    position: absolute;
    z-index: 3;
    bottom: 0;
    height: 70%;
    width: 100%;
}

.shop-mobile-upcoming {
    position: absolute;
    z-index: 3;
    bottom: 0;
    height: 10%;
    width: 100%;
}

.with-cart-checkout {
    animation-name: extend-cart-checkout;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

@keyframes extend-cart-checkout {
    from {height: 0%;}
    to {height: 80%;}
}

.with-product-to-cart {
    animation-name: extend-product-to-cart;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    z-index: 9 !important;
}

.no-product-to-cart {
    animation-name: remove-product-to-cart;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}
@keyframes remove-product-to-cart {
    from {height: 80%;}
    to {height: 60%;}
}

@keyframes extend-product-to-cart {
    from {height: 0%;}
    to {height: 100%;}
}

.shop-mobile-main-chat-container {
    animation-name: extend-chat;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

@keyframes extend-chat {
    from {height: 10%;}
    to {height: 85%;}
}

.shop-mobile-main-chat-exit-container {
    animation-name: extend-chat-exit;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

@keyframes extend-chat-exit {
    from {height: 85%;}
    to {height: 10%;}
}

.shop-mobile-parent-container {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.shop-mobile-main-container {
    animation-name: extend-before;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

@keyframes extend-before {
    from {height: 100%;}
    to {height: 0%;}
}

.shop-mobile-main-container-2 {
    animation-name: extend;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    /* bottom: calc(-50vw + 33%); */
}

@keyframes extend {
    from {height: 0%;}
    to {height: 80%;}
}

.no-products {
    height: 0%;
}

.shop-mobile h1{
    color: white;
    cursor: pointer;
}

.shop-open {
    transition: 0.5s;
}

.shop-close {
    display: none;
    transition: 0.5s;
}

.shop-container {
    width: 100%;
    height: 90%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: white;
}

.shop {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.shop::-webkit-scrollbar {
    display: none;
}

.products-mobile {
    width: 100%;
    margin-top: 17px;
    margin-bottom: 34px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-evenly;
}

.product-container {
    width: 100%;
    height: 33.3333%;
    padding-top: 12px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 12px;
    border-bottom: solid 1px #D2CBF3;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.product-description {
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-description span:nth-child(1) {
    display: block;
    font-size: 14px;
}

.product-description span:nth-child(2) {
    font-size: 11px;
}

.product-arrow {
    transform: rotate(90deg);
}

.product-to-cart {
    padding-top: 1.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1.875rem;
    width: 100%;
    height: 100%;
    background-color: white;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    overflow: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.product-to-cart::-webkit-scrollbar {
    display: none;
  }

.product-to-cart-image {
    display: block;
    width: 100%;
    height: 19.3125rem;
    margin-bottom: 20px;

}
@media screen and (max-width: 39.9375em) {
    .product-to-cart-image {
    width:auto;
    height: 200px;
    margin:auto;
    }
    .product-to-cart button{
        position:absolute;
        width: 300px !important;
        bottom: 10%;
        left: 50%;
        transform: translateX(-50%);
    }
}
.product-to-cart-details {
    margin-bottom: 1.5625rem;
}

.product-to-cart-details span:nth-child(1) {
    display: block;
    font-size: 1.25rem;
    padding-bottom: 10px;
}

.product-to-cart-details span:nth-child(2) {
    display: block;
    font-size: 0.875rem;
    height: 100px;
    overflow-y: auto;
}

.product-to-cart button {
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border: none;
    border-radius: 80px;
    outline: none;
    background-color: #1D1D1D;
    color: #F7F8FB;
    text-transform: uppercase;
}

.back-to-products-header {
    position: sticky;
    /* background-color: white; */
    padding-top: 0;
    height: 10%;
    top: -1rem;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding-left: 0px;
    margin-bottom: 0.2rem;
    /* -webkit-box-shadow: 0px 23px 50px -10px rgba(0,0,0,0.5);
    box-shadow: 0px 23px 50px -10px rgba(0,0,0,0.5); */
}

.product-to-cart-variants-select {
    width: 100%;
    font-size: 0.8125rem;
    padding: 0.9375rem 1.59375rem;
    color: #929CA1;
    outline: none;
    border: solid 1px #B8D2E2;
    border-radius: 131px;
    background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #F8F8F8;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;       /* Remove default arrow */
    background-position-x: 90%;
}


.back-to-products-header span {
    font-size: 15px;
    font-weight: 600;
}

#back-to-products {
    transform: rotate(-90deg);
    margin-right: 20px;
}

#close-checkout {
    margin-right: 20px;
}

.checkout-cart-header {
    position: sticky;
    background-color: white;
    padding-top: 0;
    width: 90%;
    margin: 0 auto;
    height: 10%;
    top: -1rem;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding-left: 10px;
    margin-bottom: 0.2rem;
    -webkit-box-shadow: 0px 23px 50px -10px rgba(0,0,0,0.25);
    box-shadow: 0px 23px 50px -10px rgba(0,0,0,0.25);
}

.checkout-cart-header span {
    font-size: 15px;
    font-weight: 600;
}

.checkout-cart {
    margin-top: 13px;
    padding-top: 1.5rem;
    padding-bottom: 1.875rem;
    width: 100%;
    height: 100%;
    background-color: white;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    overflow: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.checkout-cart::-webkit-scrollbar {
    display: none;
}

.checkout-cart-footer {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
}

.checkout-btn {
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background: none;
    background-color: #1D1D1D;
    color: white;
    text-transform: uppercase;
    outline: none;
    border: none;
    border-radius: 87px;
}

.checkout-total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 21px;
}

.checkout {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: white;
}

.checkout-item {
    padding-top: 16.5px;
    padding-left: 2rem;
    padding-right: 18px;
    padding-bottom: 16.5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px #D2CBF3;
}

.checkout-items {
    max-height: 80%;
    margin-bottom: 10px;
    overflow: hidden;
    overflow-y: auto;
    overflow: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.checkout-items::-webkit-scrollbar {
    display: none;
}

.checkout-item-content {
    padding-left: 12px;
    flex-grow: 1;
}

.checkout-item-image {
    width: 102px;
    height: 90px;
}

.checkout-item-content-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.checkout-item-content-header button {
    background: none;
    outline: none;
    border: none;
    text-decoration: underline;
}

.checkout-item-content-body {
    width: 180px;
    white-space: nowrap ;
    text-overflow: ellipsis;
    font-size: 11px;
    overflow: hidden;
    margin-bottom: 5px;
}

.checkout-item-content-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.checkout-item-content-quantity-controller {
    width: 30%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border: solid 1px #E4E4E4;
    border-radius: 29px;
}

.checkout-item-content-quantity-controller span {
    font-size: 12px;
}

.checkout-item-content-quantity-controller button {
    border: none;
    outline: none;
    background: none;
    font-size: 12px;
    padding: 5px 10px;
}

.cart-no-items {
    display: flex;
    width: 100%;
    height: 75%;
    justify-content: center;
    align-items: center;
}

.no-items-container {
    width: 100%;
    text-align: center;
}

.no-items-container h3 {
    font-size: 18px;
    font-weight: 500;
}

.checkout-exit {
    animation-name: checkout-exit;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

@keyframes checkout-exit {
    from {
        height: 100%;
    }
    to {
        height: 0%;
    }
}

.product {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.product-image {
    width: 65px;
    height: 62px;
    object-fit: cover !important;
    border-radius: 5px;
}

.product-page {
    width: 95%;
    height: 80%;
    position: absolute;
    bottom: 0;
    z-index: 8;
    background-color: white;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    transition: 0.5s;
}

.rec.rec-carousel {
    height: 80%;
    transition: 0.5s;
}

.rec.rec-pagination {
    display: none;
}

.rec.rec-arrow-left {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    opacity: 0.7;
}

.rec.rec-arrow-left, .rec.rec-arrow-right {
    position: absolute;
    background-color: rgba(250,250,250,1);
    color: black;
    z-index: 5;
    border-radius: 0;
    opacity: 0.7;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0px 2px 19px -2px rgba(0,0,0,0.77);
    -webkit-box-shadow: 0px 2px 19px -2px rgba(0,0,0,0.77);
    -moz-box-shadow: 0px 2px 19px -2px rgba(0,0,0,0.77);
}

.rec.rec-arrow-left:disabled{
    opacity: 0.7;
    background-color: rgba(250,250,250,1);
    color: black;
    cursor: pointer;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.rec.rec-arrow-right {
    right: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    opacity: 0.7;
}

.rec.rec-arrow-right:hover {
    background-color: rgba(250,250,250,1);
    opacity: 0.7;
}

.rec.rec-arrow-right:hover:enabled {
    background-color: rgba(250,250,250,1);
    color: black;
    opacity: 0.7;
    box-shadow: 0px 2px 19px -2px rgba(0,0,0,0.77);
    -webkit-box-shadow: 0px 2px 19px -2px rgba(0,0,0,0.77);
    -moz-box-shadow: 0px 2px 19px -2px rgba(0,0,0,0.77);
}

.rec.rec-arrow-left:hover:enabled {
    background-color: rgba(250,250,250,1);
    opacity: 0.7;
    color: black;
    box-shadow: 0px 2px 19px -2px rgba(0,0,0,0.77);
    -webkit-box-shadow: 0px 2px 19px -2px rgba(0,0,0,0.77);
    -moz-box-shadow: 0px 2px 19px -2px rgba(0,0,0,0.77);
}

.rec.rec-arrow-right:disabled {
    opacity: 0.7;
    background-color: rgba(250,250,250,1);
    color: black;
    cursor: pointer;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.chat-container-mobile {
    width: 100%;
    padding-left: 13px;
    padding-right: 13px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: white;
    padding-bottom: 12%;
    padding-top: 10px;
    height: 100%;
}

.comment-section-mobile {
    height: 190px;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    z-index: 7;
}

input[type="text"] {
    font-size: 16px;
}

.comment-section-mobile div {
    bottom: 0;
}

.comment-input-container-mobile {
    display: flex;
    align-self: center;
    align-items: center;
    border:solid 1px #ccc;
    border-radius: 5px;
    padding-right: 0.5rem;
}

.comment-input-container-mobile input[type="text" i] {
    background: transparent;
    width: 100%;
    height: 40px;
    padding: 1.125rem 0.5rem;
    border: none;
    font-size: 14px;
    color: #000;
}

.comment-input-container-mobile input[type="text" i]::placeholder {
    color: #000;
}

.comment-input-container {
    width: 100%;
    height: 10%;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    align-items: center;
    align-content: center;
}

.close-button-mobile {
    height: 1.6875rem;
    width: 1.6875rem;
    background-color: black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 1.0625rem;
    margin-right: 0.875rem;
    z-index: 10;
}

.lds-hourglass {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-hourglass:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: 32px solid black;
    border-color: black transparent black transparent;
    animation: lds-hourglass 1.2s infinite;
  }
  @keyframes lds-hourglass {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }

  .cart-container {
      position: absolute;
      width: 40px;
      height: 40px;
      z-index: 10;
      top: 0;
      display: flex;
      align-items: center;
      right: 15%;
      margin-top: 13px;
      padding-left: 10px;
      border-radius: 50%;
      background:  #6d6565 0% 0% no-repeat padding-box;;
  }

  .play-pause {
    position: absolute;
    width: 40px;
    height: 40px;
    z-index: 10;
    top: 0;
    display: flex;
    align-items: center;
    right: 30%;
    margin-top: 13px;
    padding-left: 10px;
    border-radius: 50%;
    background:  white 0% 0% no-repeat padding-box;;
}

.cart-container img {
    width: 1rem;
    height: 1rem;
  }

.cart-container span {
    position: absolute;
    display: inline-block;
    padding: 3px 6px;
    right: 0;
    background-color: white;
    border-radius: 50%;
    font-size: 10px;
    border: solid 1px #6d6565;
    font-weight: 600;
    top: 0;
}
