.cart_container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 90%;
  animation: opacity-animation 500ms ease-in-out 1;
}

.products_container {
  height: 86%;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.product_details_container {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.8rem 0.5rem 0.5rem;
  border-bottom: 1px solid hsla(0, 0%, 84.7%, 0.4);
  width: 100%;
}

.product_img_container {
  display: flex;
  align-items: baseline;
}

.product_img {
  height: 6.5rem;
  width: 6.5rem;
  object-fit: cover;
  border-radius: 5px;
}

.product_specifics_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0rem 0rem 0rem 0.5rem;
  width: 100%;
}

.product_specifics_top_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
}

.product_specifics_middle_container {
  display: flex;
  align-items: center;
  width: auto;
}

.product_specifics_bottom_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
}

.product_price {
  font-size: 15px;
  display: flex;
}

.product_name {
  font-size: 12px;
  width: 13rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.25rem;
  padding-top: 0.3rem;
}

.product_qty_controller_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e4e4e4;
  border-radius: 29px;
  width: 4.4rem;
  margin-top: 0.3rem;
}

.product_qty_ctrl_btn {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 100%;
  transition: all 100ms ease-in;
  justify-content: center;
}

.product_qty_ctrl_btn:focus {
  outline: none;
}

.product_qty_ctrl_btn:hover {
  background: rgb(245, 245, 245);
}

.product_qty_ctrl_btn:active {
  background: rgb(230, 230, 230);
}

.minus_icon {
  height: 0.5rem;
  width: 0.5rem;
}

.product_qty_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.5rem;
  overflow-x: auto;
}

.product_qty {
  font-size: 12px;
  user-select: none;
  width: 4.4rem;
  text-align: center;
}

.plus_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.5rem;
  width: 0.5rem;
  color: rgb(90, 90, 90);
  margin-top: -1px;
  user-select: none;
}

.remove_button_container {
  display: flex;
  align-items: center;
  padding-bottom: 1px;
}

.remove_btn {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: underline;
  transition: all 75ms ease-in;
  font-size: 11px;
  color: grey;
}

.remove_btn:focus {
  outline: none;
}

.remove_btn:hover {
  color: rgb(182, 182, 182);
}

.remove_btn:active {
  color: rgb(238, 227, 222);
}

.total_price_per_product {
  font-weight: bold;
  font-size: 15px;
}

.remove_icon {
  height: 1rem;
  width: 1rem;
}

.checkout_details {
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
}

.total_details_container {
  padding: 0.5rem 3rem;
  display: flex;
  justify-content: space-between;
}

.total_text {
  font-weight: bold;
}

.total_float {
  font-weight: bold;
}

.checkout_btn_container {
  padding-top: 0.3rem;
}

.checkout_btn {
  border: none;
  color: white;
  background-color: #1d1d1d;
  border-radius: 5.438rem;
  width: 100%;
  padding: 1rem 0rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: bold;
  font-size: 0.9rem;
  cursor: pointer;
}

.checkout_btn:focus {
  outline: none;
}

.checkout_btn:hover {
  outline: none;
}

.no_items_in_cart_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 90%;
  width: 100%;
  margin-top: -2.8rem;
}

.no_items_cart_icon {
  width: 47.917px;
  height: 50px;
}

.no_items_cart_text {
  padding-top: 1rem;
}

@keyframes opacity-animation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 4;
  }
  100% {
    opacity: 1;
  }
}
