
a {
    text-decoration: none;
    color: black;
}

.video-component {
    min-width: 135px;
    width: 100%;
    max-width: 290px;
    min-height: 134px;
    height: 80%;
    max-height: 266px;
    background-color: gray;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-component:hover img {
    transform: scale(1.5);
}

.thumbnail {
    width: 100%;
    height: 100%;
}

.video-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 20%;
    padding-top: 3%;
    padding-bottom: 3%;
}

.video-title-container span {
    font-size: 17px;
    font-weight: 500;
    text-decoration: none;
}

@media screen and (max-width: 800px) {
    .video-title-container span {
        font-size: 10px;
        font-weight: 500;
        text-decoration: none;
    }
}
@media screen and (max-width: 470px) {
    .video-title-container span {
        font-size: 10px;
        font-weight: 500;
        text-decoration: none;
    }
}