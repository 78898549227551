* {
  box-sizing: border-box;
}

.play-shop-container-desktop {
  width: 100%;
  max-width: 1140px;
  min-height: 100vh;
  max-height: 100vh;
  background-color: white;
  margin: 0 auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  display: flex;
}

.play-shop-container::-webkit-scrollbar {
  display: none;
}

.video-container {
  height: 100vh;
  width: calc((100%) / 3);
}

.comment-container,
.shop-desktop-container {
  height: 100vh;
  width: calc((100%) / 3);
}

.comment-container {
  background-color: white;
}
.video-container {
  position: relative;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shop-desktop-container {
  background-color: white;
}

.context-container-web {
  width: 30%;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}

.context-products-container-web {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.comment-header {
  display: flex;
  align-items: center;
  height: 10%;
  width: 100%;
  padding-top: 1.4rem;
  padding-bottom: 1.25rem;
  padding-left: 1.8rem;
  border-bottom: solid 1px hsla(0, 0%, 84.7%, 0.4);
}

.comment-header p {
  font-weight: 400;
  font-size: 1.3rem;
  text-transform: uppercase;
}

.comment-load-more {
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px #00000029;
  text-align: center;
  padding: 5px;
  cursor: pointer;
  margin-bottom: 1rem;
}

.comment-section {
  height: 75%;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
}

.comment-input-container {
  width: 100%;
  height: 10%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  align-items: center;
  align-content: center;
  border-top: solid 1px hsla(0, 0%, 84.7%, 0.4);
}

.comment-input {
  width: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  border: solid 1px #ccc;
  border-radius: 78px;
  padding-right: 1rem;
  padding-left: 0.9rem;
}

.comment-input input[type='text' i] {
  width: 100%;
  padding: 0.5rem 0.5rem;
  border: none;
}

.shop-header {
  position: relative;
  z-index: 30;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10%;
  width: 100%;
  padding-top: 1.4rem;
  padding-bottom: 1.25rem;
  padding-left: 1.8rem;
  border-bottom: solid 1px hsla(0, 0%, 84.7%, 0.4);
}

.shop-header p {
  font-weight: 400;
  font-size: 1.3rem;
}

.shop-header-left-panel-desktop {
  display: flex;
  align-items: center;
}

.shop-header-right-panel-desktop {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1rem;
}

.shop-header-right-panel-desktop button {
  position: relative;
  border: none;
  background: none;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.shop-header-right-panel-desktop button:focus {
  outline: none;
}

.shop-header-right-panel-desktop button:hover {
  outline: none;
}

.cart-badge-number {
  position: absolute;
  top: 0%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  font-size: 0.7rem;
  font-weight: normal;
  width: 1.3rem;
  height: 1.3rem;
  border: 1px solid black;
  border-radius: 100%;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
}

.left-arrow-shop-header-desktop {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

.arrow-icon-button-desktop {
  margin-right: 1rem;
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.arrow-icon-button-desktop:focus {
  outline: none;
}

.cart-desktop {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  user-select: none;
}

.shop-products-container,
.other-videos-container {
  height: 88%;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  border-bottom: solid 1px hsla(0, 0%, 84.7%, 0.4);
}

.product-list-container-desktop {
  animation: opacity-animation 500ms ease-in-out 1;
}

textarea:focus,
input:focus {
  outline: none;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.react-player video {
  object-fit: cover !important;
  cursor: pointer;
  outline: none;
}

.react-player img {
  object-fit: cover !important;
}

.integrated-product-container-desktop {
  animation: opacity-animation 500ms ease-in-out 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 1rem 1rem 1rem;
}

.image-integrated-product-container-desktop {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-integrated-product {
  object-fit: contain;
  width: 85%;
  height: 85%;
  border-radius: 5px;
}

.price-and-name-container-desktop {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem;
  width: 100%;
  /* width: rem; */
}

.price-and-name-container-desktop span {
  font-size: 1.25rem;
}

.price-and-name-container-desktop p {
  padding-top: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.675;
}

.call-to-action-integrated-product-button-desktop {
  background-color: #1d1d1d;
  border: none;
  border-radius: 5.438rem;
  margin-top: 1rem;
  /* width: 21.938rem; */
  width: 100%;
  height: 3.438rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.call-to-action-integrated-product-button-desktop span {
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  font-size: 1rem;
}

.call-to-action-integrated-product-button-desktop:focus {
  outline: none;
}

.check-mark-desktop {
  padding-right: 1rem;
}

.variants-dropdown-container-desktop {
  /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%; */
  width: 100%;
  position: relative;
  margin-top: 1rem;
  z-index: 20;
}

.variants-dropdown-button-desktop {
  border: 1px solid #b8d2e2;
  background: #f8f8f8;
  width: 100%;
  padding: 1rem;
  border-radius: 8.188rem;
  cursor: pointer;
  color: rgb(118, 118, 118);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.9rem;
  transition: all 100ms ease-in;
  user-select: none;
}

.variant-dropdown-chevron-down-svg {
  width: 1.2rem;
  height: 1.2rem;
}

.variants-dropdown-button-desktop:hover {
  outline: none;
  background: #f3f3f3;
}

.variants-dropdown-button-desktop:focus {
  outline: none;
  border: 1px solid #91bfdc;
}

.variants-dropdown-button-desktop:active {
  outline: none;
  border: 1px solid #91bfdc;
}

.variants-list-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: rgb(243, 243, 243);
  height: 10rem;
  max-height: 17.198rem;
  overflow-y: auto;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border: 1px solid rgb(208, 208, 208);
  animation: opacity-animation 250ms ease-in-out 1;
  z-index: 20;
}

.variant-item-button {
  background: none;
  border: none;
  text-align: start;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  color: rgb(150, 150, 150);
  font-size: 0.9rem;
  transition: all 60ms ease-in;
  width: 100%;
  cursor: pointer;
  user-select: none;
}

.variant-item-button:hover {
  outline: none;
  background: #b8d2e2;
  color: rgb(0, 0, 0);
}

.variant-item-button:focus {
  outline: none;
}

.variant-item-button-selected {
  background: none;
  border: none;
  text-align: start;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  background: #dfe2e4;
  color: rgb(150, 150, 150);
  font-size: 0.9rem;
  transition: all 60ms ease-in;
  width: 100%;
  cursor: pointer;
  user-select: none;
}

.variant-item-button-selected:hover {
  outline: none;
  background: #b8d2e2;
  color: rgb(0, 0, 0);
}

.variant-item-button-selected:focus {
  outline: none;
}

.variant-dropdown-overlay-desktop {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.poster-container-desktop {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(22, 22, 22);
  opacity: 5;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.poster-desktop {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.play-button-container {
  padding: 5px 5px;
  background-color: #30353d;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 5;
  height: 80px;
  width: 80px;
  transition: all 100ms ease-in;
  cursor: pointer;
}

.event-banner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  z-index: 5;
}

.event-text {
  position: absolute;
  top: 56%;
  z-index: 5;
  color: rgb(238, 238, 238);
  font-size: 1.1rem;
  letter-spacing: 0.025em;
}

.stream-ended-text {
  position: absolute;
  top: 46%;
  z-index: 5;
  color: rgb(238, 238, 238);
  font-size: 1rem;
  letter-spacing: 0.025em;
}

.stream-ended-sub-text {
  position: absolute;
  top: 50%;
  z-index: 5;
  color: rgb(187, 187, 187);
  font-size: 0.7rem;
  letter-spacing: 0.025em;
}

.upcoming-event-date {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 60%;
  z-index: 5;
  color: rgb(238, 238, 238);
  font-size: 1.5rem;
  letter-spacing: 0.025em;
}

.upcoming-event-timer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 60%;
  z-index: 5;
  color: rgb(238, 238, 238);
  font-size: 1.3rem;
  letter-spacing: 0.025em;
}

.play-button-container:focus {
  outline: none;
}

.play-button-container:hover {
  background-color: rgba(0, 0, 0, 0.542);
}

.play-button-img-desktop {
  height: 1.5rem;
  width: 1.5rem;
  margin-left: 0.5rem;
  object-fit: cover;
}

@keyframes opacity-animation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 4;
  }
  100% {
    opacity: 1;
  }
}

.loader-btn,
.loader-btn:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader-btn {
  margin: 60px auto;
  font-size: 4px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
